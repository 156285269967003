jQuery(document).ready(function ($) {
    consoleMessage: {
        console.log('%cCreated by %cTWK%cwww.thewebkitchen.co.uk', 'background: #13212E; color: #FFFFFF; padding: 5px 0px 5px 10px; margin: 25px 0px;', 'background: #13212E; color: #05E5C8; padding: 5px 10px 5px 0px; font-weight: bold;', 'background: #FFFFFF; padding: 5px 10px;');
	}

	modernizer: {
    if (!Modernizr.objectfit) {
      $('.object-fit').each(function () {
        var $container = $(this),
        imgUrl = $container.find('img').prop('src');
        if (imgUrl) {
          $container
            .css('backgroundImage', 'url(' + imgUrl + ')')
            .addClass('not-compatible');
        }
      });
    }
	}

	
  
  scrollDownLinks: {
		$(document).on('click', '.scroll-to', function (event) {
			event.preventDefault();
			$('html, body').animate({
				scrollTop: $($.attr(this, 'href')).offset().top
			}, 500);
		});

    // Facet WP scroll down function
    $(document).on('facetwp-loaded', function() {
      if ( FWP.loaded ) { // Run only after the initial page load
        $('html, body').animate({
          scrollTop: $('.facetwp-template').offset().top - 180 // Scroll to the top of the element with class "facetp-template"
        }, 500);
      }
    });
	}

  externallinks: {
		$('a:not(.magnific-video)').each(function () {
			var a = new RegExp('/' + window.location.host + '/');
			if (!a.test(this.href)) {
				$(this).click(function (event) {
					event.preventDefault();
					window.open(this.href, '_blank');
				});
			}
		});
		pdfs: {
			///OPEN PDFS IN NEW WINDOW
			$(function () {
				$('a[href$=".pdf"]').prop('target', '_blank');
			});
		}
	}
  
  
  
  youtubeEmbed: {
    $('iframe[src*="youtube"]').wrap("<div class='iframe-video'></div>");
  }





	fixedheader: {
		$(function () {
			$(window).scroll(function () {
				if ($(window).scrollTop() >= 190) {
					$('.js-sticky-nav').addClass('fixed-header');
          $('.header__first').css('marginBottom', '58px');
				}
				if ($(window).scrollTop() >= 210) {
					$('.js-sticky-nav').addClass("transition");
				} else {
					$('.js-sticky-nav').removeClass('fixed-header');
          $('.header__first').css('marginBottom', '0')
					$('.js-sticky-nav').removeClass("transition");
				}
			});
		});
	}




  mainMenu: {
    // Open submenu on click
    $(".js-sticky-nav .menu-item-has-children > a").on("click", function (e) {
      e.preventDefault();
  
      // If current link is already open, close submenu
      if ( $(this).closest(".menu-item-has-children").hasClass("sub-menu-open") ) {
        $(this).closest(".menu-item-has-children").removeClass("sub-menu-open");
        $(this).next(".sub-menu").removeClass("sub-menu-open");
      } else {
        $(".menu-item-has-children").removeClass("sub-menu-open");
        $(".sub-menu").removeClass("sub-menu-open");
  
        $(this).closest(".menu-item-has-children").toggleClass("sub-menu-open");
        $(this).next(".sub-menu").toggleClass("sub-menu-open");
      }
    });
    // close submenu on mobile
    $(".sub-menu .back-menu").on("click", function (e) {
      e.preventDefault();
      $(".sub-menu").removeClass("sub-menu-open");
      $(".menu-item-has-children").removeClass("sub-menu-open");
    });
  
    // Open menu with tabs - accessibility
    $(".js-sticky-nav .sub-menu a").on("focusin", function () {
      $(this).closest(".menu-item-has-children").addClass("sub-menu-open");
      $(this).closest(".sub-menu").addClass("sub-menu-open");
    });
    $(".js-sticky-nav .sub-menu a").on("focusout", function () {
      $(this).closest(".menu-item-has-children").removeClass("sub-menu-open");
      $(this).closest(".sub-menu").removeClass("sub-menu-open");
    });
  
    // Close on click outside.
    $(document).on("click", function (e) {
      if ( !$(e.target).parent().hasClass("menu-item") && $(".sub-menu").has(e.target).length === 0 ) {
        $(".menu-item-has-children").removeClass("sub-menu-open");
        $(".sub-menu").removeClass("sub-menu-open");
      }
    });
    
  
    // ESCAPE key to close submenu
    $(document).keydown(function (e) {
      if (e.keyCode == 27) {
        $(".menu-item-has-children").removeClass("sub-menu-open");
        $(".sub-menu").removeClass("sub-menu-open");
      }
    });
  }


	mobileMenu: {
    // Mobile menu
    var $mobileMenu = $(".mobile-menu-button");
    $mobileMenu.on("click", function(e) {
      $mobileMenu.toggleClass("is-active");
      $('.mobile-menu-button-wrapper').toggleClass('is-active');
      $('.header nav').toggleClass('is-active');
      $('body').toggleClass('mobile-menu-is-active');
    });
	}





  slickSlider: {
		$('.js-slider-posts').slick({
			dots: true,
      arrows: false,
      fade: true,
      autoplay: true,
      autoplaySpeed: 4500,
    });
    $('.js-slider-explainers').slick({
			dots: true,
      arrows: false,
      fade: true,
      autoplay: true,
      autoplaySpeed: 4500,
    });
		$('.article-slider__images').slick({
			dots: false,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 4500,
    });
    $('.js-quote-slider').slick({
        dots: true,
        arrows: false,
        autoplay: false,
        autoplaySpeed: 4500,
        fade: true,
        asNavFor: $('.js-quote-img-slider')
    });
    $('.js-quote-img-slider').slick({
        dots: false,
        arrows: false,
        autoplay: false,
        autoplaySpeed: 4500,
        fade: true,
        asNavFor: $('.js-quote-slider')
    });

  
	}



  swiperSlider: {
    const swiperLatestArticles = new Swiper('.js-slider--latest-articles', {
      slidesPerView: 1,
      spaceBetween: 30, 
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 1,
          spaceBetween: 20
        },
        // when window width is >= 480px
        580: {
          slidesPerView: 2,
          spaceBetween: 30
        },
        // when window width is >= 640px
        900: {
          slidesPerView: 3,
          spaceBetween: 40
        },
        // when window width is >= 640px
        1200: {
          slidesPerView: 4,
          spaceBetween: 40
        }
      },     
      navigation: {
        nextEl: '.swiper-next',
        prevEl: '.swiper-prev',
      },
      scrollbar: {
        el: '.swiper-scrollbar',
        draggable: true,
      }
    });
  }



	magnific: {
		///IFRAME
		$('.magnific-video').magnificPopup({
			type: 'iframe', 
      mainClass: 'mfp-fade', 
      removalDelay: 160, 
      preloader: false, 
      fixedContentPos: false
    });

		// INLINE
		$('.inline-popup').magnificPopup({
      type: 'inline',
      mainClass: 'mfp-fade',
      removalDelay: 160,
      preloader: false,
			fixedContentPos: true,
			closeMarkup: '<div title="%title%" class=" mfp-close"></div>'
		});

    // GALLERY IMAGE
    $('.magnific-gallery-image').magnificPopup({
      type: 'image',
      closeOnContentClick: true,
      mainClass: 'mfp-img-mobile',
      image: {
        verticalFit: true,
        titleSrc: function(item) {
          return item.el.attr('title');
        }
      },
      gallery:{
        enabled:true
      }
    });
	}








  tabs: {
    $('#language-tabs li a:not(:first)').addClass('inactive');
    $('.tab-pane').hide();
    $('.tab-pane:first').show();

    $('#language-tabs li a').click(function(){
      var t = $(this).attr('id');
      if($(this).hasClass('inactive')){ //this is the start of our condition
        $('#language-tabs li a').addClass('inactive');
        $(this).removeClass('inactive');

        $('.tab-pane').hide();
        $('#'+ t ).fadeIn('slow');
      }
    });
  }





	search: {
		$('.js-open-search').on('click', function (e) {
			$('.search-overlay').toggleClass('is-active');
			setTimeout(function () {
				$('.js-close-search').fadeIn();
        $('.search-overlay .search-field').focus();
			}, 300);
		});
		$('.js-close-search').on('click', function (e) {
			$(this).fadeOut();
			$('.search-overlay').removeClass('is-active');
		});

    // Close search on esc key click
    $( document ).on( 'keydown', function ( e ) {
      if ( e.keyCode === 27 ) { // ESC
        $('.search-overlay').removeClass('is-active');
      }
    });

	}


  theme: {
    $('.theme a').on('mouseenter', function() {
      let target = $(this).attr('target-img');
      $('.teasers__image__slide:not(.active-slide)').fadeOut();
      $(target).fadeIn();
    });
  }



  toggle: {
    $('.button-toggle').on('click', function() {
      $('.toggle-button-cover span').toggleClass('active');
      $('.staff-list-fellows__grid > div').toggle();
    });
  }




  accordion: {
    if ($('.block--accordion').length) {
      $('.block--accordion').find('.js-accordion-trigger').click(function() {
        if ($(this).hasClass('open')){
          $(this).next().slideUp('fast');
          $(this).removeClass('open');
          $(this).parent('.accordion__item').removeClass('open-parent');
        } else {
          $(this).next().slideDown('fast');
          $(this).addClass('open');
          $(this).parent('.accordion__item').addClass('open-parent');
        }     
      });
    }
  }


  peoplePage: {
    if ( $('body').hasClass('page-template-tpl-people') ) {
      // Show more toggle
      $('.js-open-more a').on('click', function(e) {
        e.preventDefault();
        $(this).parent().parent().find('.js-hidden').toggleClass('js-hidden');
        $(this).parent().hide();
      }); 


      // Fellows toggle
      $('.fellows-toggle').on('click', function(e) {
        e.preventDefault();
        var id = $(this).attr('href');
        console.log($(this));
        $('.fellows-wrapper').removeClass('active');
        $(id).toggleClass('active');
      }); 
    }
  }




	loadingAnimations: {
		$.fn.isOnScreen = function () {
			var win = $(window);
			var viewport = {
				top: win.scrollTop()
			, };
			viewport.bottom = viewport.top + win.height() - 100;
			var bounds = this.offset();
			bounds.bottom = bounds.top + this.outerHeight();
			return (!(viewport.bottom < bounds.top || viewport.top > bounds.bottom));
		};
		$(window).scroll(function () {
			$('.off-screen').each(function (index) {
				if ($(this).isOnScreen()) {
					$(this).removeClass('off-screen--hide');
				}
			});
		});
	}
	onLoad: {
		$(document).ready(function () {
			$('.off-screen').each(function (index) {
				if ($(this).isOnScreen()) {
					$(this).removeClass('off-screen--hide');
				}
			});
		});
	}

});
